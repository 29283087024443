import type { SimpleHeadlineResponseSimpleSiteResponseRecordRelatedPagedData } from '@on3/api';
import { useAuth, useSite } from '@on3/ui-lib/index';
import CalloutButton from 'components/UI/Buttons/CalloutButton';
import { Text } from 'components/UI/Typography/Text';

import { PremiumArticle } from './PremiumArticle';
import styles from './PremiumArticle.module.scss';

export const PremiumArticleList = ({
  list,
  relatedModel,
}: SimpleHeadlineResponseSimpleSiteResponseRecordRelatedPagedData) => {
  const { currentSite } = useSite();
  const showSiteName =
    currentSite?.isChannel ||
    (currentSite?.isTeam && currentSite?.organization?.name);
  const { user } = useAuth();

  return (
    <section className={styles.moduleContainer}>
      <header>
        <Text className={styles.title} component="h2">
          Top
          {showSiteName && (
            <span className={styles.titleIcon}>
              {currentSite?.organization?.knownAs ??
                currentSite?.organization?.name}
            </span>
          )}
          Headlines
        </Text>
      </header>
      <ul>
        {list?.slice(0, 8).map((item) => (
          <li key={item.key}>
            <PremiumArticle {...item} />
          </li>
        ))}
      </ul>
      {!user?.has && (
        <div className={styles.subscribeWrapper}>
          <Text className={styles.subscribeText}>
            Read these stories and every premium article
          </Text>
          <CalloutButton
            className={styles.subButton}
            fullWidth={false}
            href={`${currentSite?.isTeam ? currentSite?.url : ''}/join/`}
          >
            Subscribe
          </CalloutButton>
        </div>
      )}
      {user?.has && (
        <div className={styles.subscribeWrapper}>
          <Text className={styles.subscribeText}>
            Thank you for subscribing
          </Text>
          <Text className={styles.unlockText}>
            You&apos;ve unlocked access to these premium articles
          </Text>
        </div>
      )}
    </section>
  );
};
