import type { LatestForumPostsResponse } from '@on3/api';
import { ForumItem } from 'components/Forums/ForumItem';
import { SideModuleWrapper } from 'components/UI/Layout/Containers/Sidebar/SideModuleWrapper';
import { Text } from 'components/UI/Typography/Text';

import styles from './Forums.module.scss';

interface IForumsProps {
  list: LatestForumPostsResponse;
}

export const Forums = ({ list }: IForumsProps) => {
  const { title, url, primaryForum, posts } = list;

  return (
    <SideModuleWrapper title="Popular Forum Posts">
      <Text className={styles.forumName} component="h2" variant="subtitle1">
        {title}
      </Text>
      <ul className={styles.threadlist}>
        {posts?.map((post, index) => <ForumItem key={index} {...post} />)}
      </ul>
      <footer className={styles.linkContainer}>
        <a href={`${primaryForum?.url}`}>
          <Text
            className={styles.viewAllLink}
            color="primary"
            variant="subtitle1"
          >
            All Topics
          </Text>
        </a>
        <a href={`${url}`}>
          <Text
            className={styles.allForumsLink}
            color="primary"
            variant="subtitle1"
          >
            All Forums
          </Text>
        </a>
      </footer>
    </SideModuleWrapper>
  );
};
