import { GameStatuses, type ScoreboardResponse } from '@on3/api';
import { getGameTime } from '@on3/api/utils/games';
import { useSite } from '@on3/ui-lib/index';
import { slugify } from '@on3/ui-lib/utils/slugify';
import clsx from 'clsx';
import { TeamAvatar } from 'components/Image/TeamAvatar';
import Link from 'next/link';

import styles from './ScheduleModuleItem.module.scss';

export const ScheduleModuleItem = ({
  game,
  sport,
}: {
  game: ScoreboardResponse;
  sport: string;
}) => {
  const {
    startDate,
    homeTeamScore,
    awayTeamScore,
    homeTeamOrganization,
    awayTeamOrganization,
    currentOrgIsWinner,
    currentOrgIsHome,
    odds,
    statusEnum,
  } = game || {};

  const { defaultYears } = useSite();
  const opponentOrganization = currentOrgIsHome
    ? awayTeamOrganization
    : homeTeamOrganization;
  const { footballSeasonYear, nonFootballSeasonYear } = defaultYears;
  const isFootball = sport.toLowerCase() === 'football';
  const seasonYear = isFootball ? footballSeasonYear : nonFootballSeasonYear;

  const gameTime = getGameTime(game);

  const teamAvatar = opponentOrganization?.assetUrl;
  const teamName = opponentOrganization?.abbreviation;
  const teamSlug = opponentOrganization?.slug;
  const hasSite = !!opponentOrganization?.siteKeys?.length;

  const showScore = statusEnum !== GameStatuses.Scheduled;
  const isCompleted = statusEnum === GameStatuses.Completed;
  const isTie = awayTeamScore === homeTeamScore && isCompleted;
  const pointSpread = currentOrgIsHome
    ? odds?.homeHandicap
    : odds?.awayHandicap;
  const showPlusSign = pointSpread && pointSpread > 0;
  const sluggedSport = slugify(sport || 'football');

  return (
    <li className={styles.block} data-game-status={statusEnum}>
      <span>{startDate}</span>

      <span>{currentOrgIsHome ? 'vs' : '@'}</span>

      <TeamAvatar
        className={styles.logo}
        height={20}
        src={teamAvatar}
        width={20}
      />

      <div className={styles.name}>
        {hasSite ? (
          <Link
            href={`/college/${teamSlug}/${sluggedSport}/${seasonYear}/schedule/`}
          >
            {teamName}
          </Link>
        ) : (
          <span>{teamName}</span>
        )}
      </div>

      <span>
        {!!pointSpread && (
          <>
            {showPlusSign && '+'}
            {pointSpread}
          </>
        )}
      </span>

      <div className={styles.info}>
        {!showScore ? (
          <span>{gameTime}</span>
        ) : (
          <div>
            {isCompleted && (
              <span
                className={clsx(styles.result, {
                  [styles.win]: currentOrgIsWinner,
                  [styles.loss]: !currentOrgIsWinner,
                })}
              >
                {currentOrgIsWinner ? 'W' : isTie ? 'T' : 'L'}
              </span>
            )}
            <span>{`${awayTeamScore ?? 0}-${homeTeamScore ?? 0}`}</span>
          </div>
        )}
      </div>
    </li>
  );
};
