import { useSite } from '@on3/ui-lib/src/contexts/SiteProvider';
import { getAssetUrl } from '@on3/ui-lib/utils/images';
import { FanSiteAvatar } from 'components/Image/FanSiteAvatar';
import { TeamAvatar } from 'components/Image/TeamAvatar';
import { SidebarWrapper } from 'components/UI/Layout/Containers/Sidebar/SidebarWrapper';
import { TextLink } from 'components/UI/Link/TextLink';
import { Text } from 'components/UI/Typography/Text';

import styles from './SidebarTeamContainer.module.scss';

interface ISidebarTeamContainerProps {
  children: React.ReactNode;
}

export const SidebarTeamContainer = ({
  children,
}: ISidebarTeamContainerProps) => {
  const { currentSite } = useSite();
  const orgAssetUrl = getAssetUrl(currentSite?.organization?.defaultAssetRes);
  const isKsrChannel = currentSite?.url === '/college/kentucky-wildcats';
  const siteLink = isKsrChannel
    ? `/sites/${currentSite?.slug}/`
    : currentSite?.isTeam
      ? `/college/${currentSite?.slug}/`
      : `/teams/${currentSite?.slug}/`;

  const orgName =
    currentSite?.organization?.knownAs ?? currentSite?.organization?.name;

  return (
    <SidebarWrapper>
      {currentSite?.isChannel && (
        <>
          <TextLink href={siteLink}>
            <FanSiteAvatar
              className={styles.logo}
              height={30}
              name={orgName || ''}
              slug="-logo-full.png"
              width={255}
            />
          </TextLink>
          <Text className={styles.firstLine} component="h2" variant="overline">
            News From{' '}
            <TextLink color="primary" href={siteLink} variant="inherit">
              {currentSite?.siteName}
            </TextLink>
          </Text>
        </>
      )}
      {!currentSite?.isChannel && (
        <>
          <TextLink href={siteLink}>
            <TeamAvatar
              className={styles.logo}
              height={30}
              src={orgAssetUrl}
              width={30}
            />
          </TextLink>
          <TextLink href={siteLink}>
            <Text
              className={styles.firstLine}
              component="h2"
              variant="overline"
            >
              {orgName} News
            </Text>
          </TextLink>
          <Text className={styles.secondLine} component="h2" variant="overline">
            From On3 National
          </Text>
        </>
      )}
      {children}
    </SidebarWrapper>
  );
};
